<template>
  <div>
    <div
      v-loading="upLoading"
      element-loading-text="请稍等，图片上传中"
      class="EditorWrap"
      style="border: 1px solid #ccc"
    >
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 500px; overflow-y: hidden"
        v-model="html"
        :mode="mode"
        :defaultConfig="editorConfig"
        @onCreated="onCreated"
        @onChange="onChange"
      />
    </div>
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getStore } from "@/util/store.js";
export default {
  name: "ZEditor",
  props: {
    // 富文本
    value: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "zEditorChange",
  },
  components: {
    Editor,
    Toolbar,
  },
  created() {
    this.html = this.value;
  },
  data() {
    return {
      editor: null,
      mode: "default",
      html: "",
      toolbarConfig: {
        excludeKeys: [
          "headerSelect",
          "blockquote",
          "group-video",
          "insertTable",
          "code",
          "emotion",
          "todo",
          "codeBlock",
          "fullScreen",
        ],
      },
      editorConfig: {
        placeholder: "请输入...",
        MENU_CONF: {
          uploadImage: {
            meta: {
              mediaType: "image",
              title: "",
              introduction: "",
            },
            allowedFileTypes: ["image/*"],
            maxFileSize: 10 * 1024 * 1024, // 10M
            fieldName: "file",
            maxNumberOfFiles: 1,
            headers: this.headers(),
            server: this.server(),
            withCredentials: false,
            onBeforeUpload: (files) => {
              this.upLoading = true;
              console.log(files, "files");
              return files;
            },
            customInsert(res, insertFn) {
                console.log(res,'ddddd');
              if (res.code =='200') {
                insertFn(res.data.url, res.data.name, res.data.url);
              } else {
                console.log("上传失败");
              }
            },
            onSuccess: (file, res) => {
              this.upLoading = false;
              console.log(`${file.name} 上传成功`, res);
            },
            onError: (file, err, res) => {
              console.log(err, res, "上传失败");
              this.$message.error("上传出错：" + res.msg);
            },
          },
        },
      },
      upLoading: false,
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onChange() {
      this.$emit("zEditorChange", this.html);
    },
    headers() {
      let headers = {};
      const token =
        getStore({
          name: "access_token",
        }) || "";
      headers["Authorization"] = "Bearer " + token; // token
      return headers
    },
    server() {
      const baseURL = process.env.VUE_APP_API_BASE_URL
      let url = baseURL + '/admin/oss/upload'
      console.log(url,'dddd');
      return url
    }
  },
};
</script>
<style lang="scss" scoped>
</style>